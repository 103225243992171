import { sendAppierEvent } from "@/utils/appier";
import { useEffect } from "react";
import { useRouter } from 'next/router';

const SERVICE_NAME = "月租";
const SERVICE_ID = "UPASS_TW";
const SERVICE_PLATFORM = "web";
const RELEASE_VERSION = "20241012v1";
const TRACKING_STORAGE_KEY = `_${SERVICE_ID}-${SERVICE_PLATFORM}-${RELEASE_VERSION}_viewed_date`;
export default function TrackingHandler() {
  const router = useRouter();
  useEffect(() => {
    if (!window) return;
    if (window.location.pathname.includes("callback")) return;

    const _service_viewed_date = window.localStorage.getItem(TRACKING_STORAGE_KEY);
    if (_service_viewed_date) {
      const _service_viewed_date_time = parseInt(
        _service_viewed_date
      );
      const _service_viewed_date_now = new Date().getTime();

      if (
        _service_viewed_date_now - _service_viewed_date_time <
        1800000
      ) {
        return;
      }
    }

    sendAppierEvent("service_viewed", {
      service_name: SERVICE_NAME,
      service_id: SERVICE_ID,
      service_url: window.location.pathname,
      platform: SERVICE_PLATFORM,
      release_version: RELEASE_VERSION,
    });

    // 設定localStorage _service_viewed_date
    // 一小時內 | 用戶持續瀏覽 => 都不會再發送一次
    window.localStorage.setItem(
      TRACKING_STORAGE_KEY,
      new Date().getTime()
    );
  }, [router]);
  return null;
}
