import Head from 'next/head';
import Script from 'next/script';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider as JotaiProvider } from 'jotai';
import { useRouter } from 'next/router';
import { pageview, event } from 'utils/gtag';

import { theme } from 'themes';

import Loading from '@/components/shared/Loading';
import TrackingHandler from '@/components/shared/TrackingHandler';
import '@/styles/globals.css';
import { useEffect } from 'react';

export default function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      // pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return (
    <>
      <Script
        id='gtm-init'
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WDPK6MD');
          `,
        }}
      />

      <Script
        id='clarity-init'
        dangerouslySetInnerHTML={{
          __html: `
          (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "nbnh72yv7a");
          `,
        }}
      />

      {/** Appier */}
      <Script
        id='appier-init'
        dangerouslySetInnerHTML={{
          __html: `
          !function(q,g,r,a,p,h,js) {
            q.appier=q.qg;
            if(q.qg)return;
            js=q.appier=q.qg=function() {
              js.callmethod ? js.callmethod.call(js, arguments) : js.queue.push(arguments);
            };
            js.queue = [];
            p = g.createElement(r);
            p.async = !0;
            p.src = a;
            h = g.getElementsByTagName(r)[0];
            h.parentNode.insertBefore(p, h);
          } (window, document, 'script', 'https://cdn.qgr.ph/qgraph.9d4db29fccff1241c86e.js');
          `,
        }}
      />
      {/* <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=G-FJMSPJ36ED`}
      />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-FJMSPJ36ED', {
                  page_path: window.location.pathname,
                });
          `,
        }}
      /> */}

      <Head>
        <title key='title'>
          USPACE 月租車位｜車位輕鬆找，搜尋快速無煩惱｜月租停車
        </title>

        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
        <meta
          key='description'
          name='description'
          content='USPACE 提供快速便利的月租車位尋找服務。無論是個人或企業，我們都有全面的月租停車資訊與方案，一站式滿足你的需求。'
        />
        <meta
          key='og_title'
          property='og:title'
          content='USPACE 月租車位｜車位輕鬆找，搜尋快速無煩惱'
        />
        <meta
          key='og_image'
          property='og:image'
          content='https://pass.uspace.city/share.png'
        />
        <meta key='og_url' property='og:url' content='' />
        <meta key='og_type' property='og:type' content='website' />
        <meta
          key='og_description'
          property='og:description'
          content='USPACE 提供快速便利的月租車位尋找服務。無論是個人或企業，我們都有全面的月租停車資訊與方案，一站式滿足你的需求。'
        />
        <meta
          name='google-site-verification'
          content='HPDWCwd50yfhrpAtesWsqWtT4TirfVGU0GUIyuhPxcQ'
        />
        <link
          rel='icon'
          href='https://uspace.city/wp-content/uploads/2019/11/cropped-工作區域-21-1-32x32.png'
          sizes='32x32'
        />
      </Head>

      <JotaiProvider>
        <ChakraProvider theme={theme}>
          <TrackingHandler />
          <Component {...pageProps} />
          <Loading />
        </ChakraProvider>
      </JotaiProvider>
    </>
  );
}
